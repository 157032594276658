




























import { Component, Prop, Vue } from "vue-property-decorator";

import CounterpartyNetworkResponseModel from "@/accounting-portal/models/counterparties/CounterpartyNetworkResponseModel";

@Component
export default class NetworksTable extends Vue {
  @Prop({ default: false }) hasDeleteAccess!: boolean;

  get headers(): Array<Record<string, any>> {
    return [
      {
        text: this.$lang("accountingPortal.counterparties.networks.name"),
        value: "name",
      },
      {
        text: this.$lang("accountingPortal.counterparties.networks.dateFrom"),
        value: "dateFrom",
      },
      {
        text: this.$lang("accountingPortal.counterparties.networks.dateTo"),
        value: "dateTo",
      },
      ...(this.hasDeleteAccess
        ? [
            {
              text: this.$lang("shared.actions"),
              align: "end",
              value: "actions",
              width: "10%",
              sortable: false,
            },
          ]
        : []),
    ];
  }

  get networks(): Array<CounterpartyNetworkResponseModel> {
    return this.$store.state.counterpartyStore.networks;
  }

  get loading(): boolean {
    return this.$store.state.counterpartyStore.isNetworksLoading;
  }

  emitClickByTableRow(item: CounterpartyNetworkResponseModel) {
    this.$emit("click:row", item);
  }

  deleteItem(item: CounterpartyNetworkResponseModel) {
    this.$store.dispatch("deleteCounterpartyNetwork", item.id);
  }
}
